define("discourse/plugins/discourse-workflow/discourse/templates/admin-plugins/show/discourse-workflow-workflows/new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <WorkflowListEditor
    @workflows={{this.allWorkflows}}
    @currentWorkflow={{this.model}}
  />
  */
  {
    "id": "FWXvipSX",
    "block": "[[[8,[39,0],null,[[\"@workflows\",\"@currentWorkflow\"],[[30,0,[\"allWorkflows\"]],[30,0,[\"model\"]]]],null]],[],false,[\"workflow-list-editor\"]]",
    "moduleName": "discourse/plugins/discourse-workflow/discourse/templates/admin-plugins/show/discourse-workflow-workflows/new.hbs",
    "isStrictMode": false
  });
});