define("discourse/plugins/discourse-workflow/discourse/templates/admin-plugins/show/discourse-workflow-workflows/steps/steps", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="parent-template">
    {{outlet}}
  </div>
  */
  {
    "id": "7W+QRi+k",
    "block": "[[[10,0],[14,0,\"parent-template\"],[12],[1,\"\\n  \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"div\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-workflow/discourse/templates/admin-plugins/show/discourse-workflow-workflows/steps/steps.hbs",
    "isStrictMode": false
  });
});