define("discourse/plugins/discourse-workflow/discourse/templates/admin-plugins/show/discourse-workflow-workflows/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- <DBreadcrumbsItem
    @path="/admin/plugins/discourse-workflow/workflows"
    @label={{i18n "admin.discourse_workflow.workflows.title"}}
  /> --}}
  
  <div class="discourse-workflow-workflows admin-detail">
    <DPageSubheader
      @titleLabel={{i18n "admin.discourse_workflow.workflows.title"}}
      @descriptionLabel={{i18n "admin.discourse_workflow.workflows.instructions"}}
    >
      <:actions as |actions|>
        <actions.Primary
          @label="admin.discourse_workflow.workflows.new"
          @title="admin.discourse_workflow.workflows.new"
          @route="adminPlugins.show.discourse-workflow-workflows.new"
          @routeModels="discourse-workflow"
          @icon="plus"
          class="admin-workflows-new"
        />
      </:actions>
    </DPageSubheader>
  
    <div class="workflows-list">
      {{#if this.model.content.length}}
        <WorkflowListEditor @workflows={{this.model.content}} />
      {{else}}
        {{i18n "admin.discourse_workflow.workflows.none"}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "ukqTqvuv",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"discourse-workflow-workflows admin-detail\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@titleLabel\",\"@descriptionLabel\"],[[28,[37,2],[\"admin.discourse_workflow.workflows.title\"],null],[28,[37,2],[\"admin.discourse_workflow.workflows.instructions\"],null]]],[[\"actions\"],[[[[1,\"\\n      \"],[8,[30,1,[\"Primary\"]],[[24,0,\"admin-workflows-new\"]],[[\"@label\",\"@title\",\"@route\",\"@routeModels\",\"@icon\"],[\"admin.discourse_workflow.workflows.new\",\"admin.discourse_workflow.workflows.new\",\"adminPlugins.show.discourse-workflow-workflows.new\",\"discourse-workflow\",\"plus\"]],null],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"workflows-list\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"content\",\"length\"]],[[[1,\"      \"],[8,[39,5],null,[[\"@workflows\"],[[30,0,[\"model\",\"content\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,2],[\"admin.discourse_workflow.workflows.none\"],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"actions\"],false,[\"div\",\"d-page-subheader\",\"i18n\",\":actions\",\"if\",\"workflow-list-editor\"]]",
    "moduleName": "discourse/plugins/discourse-workflow/discourse/templates/admin-plugins/show/discourse-workflow-workflows/index.hbs",
    "isStrictMode": false
  });
});